import React, { useState } from 'react';
import { X } from 'lucide-react';

import profileImage1 from '../../images/about/RM1sm.jpg';
import profileImage2 from '../../images/about/RM2sm.jpg';
import profileImage3 from '../../images/about/RMholdingPinchnukSmall.jpg';

const AboutPage = ({ currentLanguage }) => {
  const [showFurtherReading, setShowFurtherReading] = useState(false);

  const translations = {
    fr: {
      title: 'À propos',
      name: 'Renaud Marchand',
      bio: `Renaud Marchand est un artiste partageant son temps entre La France et l’Asie, après avoir vécu 12 ans à Los Angeles dans les années 80 et 90. Successivement journaliste, photographe publicitaire, producteur de films de pub, designer, restaurateur et développeur immobilier, il a toujours conservé son identité d’artiste et de photographe.`,
      technique: `Depuis 2016, Marchand crée des tableaux visuels sur Google Earth, affinant le style personnel de ses "Photopaints", y consacrant toute son énergie durant la période du Covid. C’est maintenant son activité artistique principale.`,
      photopaintsDescription: `Les Photopaints sont des captures d’images 3D générées sur Google Earth, agrandies par une IA et optimisées en chromie pour une impression optimale. Elles sont visuellement proches de la peinture, mais restent cependant de véritables photographies.`,
      furtherReading: 'Au-delà Terra Firma:',
      furtherReadingText: `<h1 class="text-2xl font-bold mb-8">Au-delà de Terra Firma: Métamorphose de la cartographie numérique</h1>

      À une époque où les frontières entre tangible et virtuel s’estompent, Renaud
Marchand émerge tel un alchimiste, transformant l’ordinaire en extraordinaire. Son
travail, fusion de sensibilité artistique et de prouesse technologique, bouscule nos
idées reçues sur la photographie, la peinture et, in fine, la réalité elle-même.

Le parcours artistique de Marchand, qui a débuté dans la photographie publicitaire, a
connu une évolution remarquable. De l’intimité tactile du film 35mm à l’ampleur des
chambres 20x25, puis à la précision clinique des scanners à plat et des capteurs
numériques, son cheminement reflète la transformation tumultueuse du medium
photographique durant ces dernières décennies.

Mais c’est sa démarche actuelle qui est la plus aboutie. En s’appropriant la vaste
base de données visuelles de Google Earth, il a de fait remplacé l’appareil photo
physique par un œil numérique omniscient, capable de scruter notre planète avec
une exhaustivité inégalée.

Les fruits de cette approche novatrice sont ses Photopaints, un néologisme aussi
intrigant que les œuvres elles-mêmes. Ces pièces occupent un espace liminal entre
photographie et peinture, leurs qualités esthétiques évoquant davantage cette
dernière malgré leurs origines photographiques. En baptisant MetavEarth la
représentation de notre planète par Google Earth, Marchand inscrit son travail dans
l’air du temps de l’immersion numérique et des réalités virtuelles.

Paradoxalement, malgré leur genèse numérique les Photopaints de Marchand
adhèrent à l’éthique rigoureuse de la photographie documentaire. Tel un
correspondant de guerre dans un théâtre virtuel, il navigue dans le simulacre de
Google Earth, son œil avisé recherchant des compositions saisissantes au sein de la
topographie numérique. Les images résultantes ne subissent qu’une post-production
minimale, limitée à la correction des couleurs et à l’utilisation d’un filtre IA améliorant
à la fois la profondeur et les détails pour assurer une impression optimale.

La force du travail de Marchand réside dans son exploitation des défaillances et
aberrations inhérentes aux algorithmes de rendu 3D de Google Earth. Ces artefacts
numériques, loin d’être considères comme des défauts par Marchand, deviennent
l’essence même de son expression artistique. À travers son objectif virtuel, la
représentation scientifiquement la plus précise de notre planète se métamorphose en
une série de poèmes visuels naïfs, presque primitifs.

Plus intrigante encore est l’absence totale d’humanité dans ces paysages. Les rues
sont désertes, les trottoirs vides. Des colosses industriels en acier se liquéfient sous
un soleil invisible, des grues de chantier défient la gravité avec la légèreté de ballons,
et les automobiles semblent absorbées par les routes mêmes qu’elles parcourent.
C’est un défilé surréaliste qui fascine autant qu’il déstabilise.

Initiés en 2016, les Photopaints de Marchand couvrent un large éventail de sujets,
des complexes industriels à l’architecture urbaine, des déserts arides aux hommages
picturaux aux grandes figures de l’architecture. Ses pérégrinations virtuelles l’ont
mené au cœur industriel de Houston, où il dévoile une Amérique figée dans une
distorsion temporelle de déclin industriel. Dans son exploration de Hong Kong,
Marchand évite les clichés éculés de gratte-ciel, révélant plutôt une ville en
perpétuelle mutation, foisonnante de parcs verdoyants et de chantiers frénétiques ;
tandis qu’à Singapour son regard acéré met en évidence l’environnement ultra-
propre qui a fait la réputation de la cité-État.

En Californie, Marchand explore les déserts mutilés par l’homme et les zones
humides de la baie de San Francisco, cherchant la beauté dans les cicatrices que la
société impose à la nature. Parallèlement, il compile une collection exhaustive des
sièges sociaux des entreprises de haute technologie de la Silicon Valley. En
juxtaposant ces deux facettes du paysage californien, Marchand offre un dialogue
visuel nuancé sur la dualité de l’entreprise humaine - notre capacité d’impact
environnemental comme d’innovation technologique.

Les Photopaints de Marchand représentent un changement de paradigme dans notre
compréhension de l’art numérique et de la photographie. En détournant l’imagerie de
Google Earth, il contribue à créer un nouveau langage artistique qui remet en
question notre perception de la réalité et de la beauté à l’ère numérique. Se tenant à
l’intersection de la technologie et de l’art, Marchand témoigne du pouvoir
transformateur des médias numériques. Entre ses mains, l’enregistrement
photographique de notre planète devient une toile d’exploration, d’interprétation et de
transcendance.`,
      imageCaption: `Gauche: autoportrait, 2001, scannogramme
Droite: autoportrait, 2017, Identités Remarquables
Ci-dessus : 100x70cm Taille Classique en caisse américaine`,
    },
    en: {
      // ... English translations (add furtherReadingText here too)
      title: 'About',
      name: 'Renaud Marchand',
      bio: `Renaud Marchand is a French artist currently dividing his time between France and Asia, following a 12-year stint in Los Angeles during the 1980s and 1990s. His multifaceted career spans journalism, advertising photography, TV commercial production, furniture design, restaurant ownership and real estate development, while consistently maintaining his identity as an artist.`,
      technique: `Since 2016, Marchand has been developing his unique "Photopaints" technique, creating artworks from Google Earth captures. This project gained momentum during the Covid-19 pandemic and has now become his primary artistic focus.`,
      photopaintsDescription: `Photopaints are captures of 3D renderings on Google Earth, upscaled by AI and color-corrected for optimal printing. Photopaints look like paintings but they are, however, true documentary photographs.`,
      furtherReading: 'Transcending Terra Firma:',
      furtherReadingText: `<h1 class="text-2xl font-bold mb-8">Transcending Terra Firma: Renaud Marchand’s MetavEarth and the Metamorphosis of Digital Cartography</h1>
      
In an era where the boundaries between the tangible and the virtual continue to blur,
Renaud Marchand emerges as an alchemist, transmuting the quotidian into the
extraordinary. His work, a seamless fusion of technological prowess and artistic
sensibility, challenges our preconceived notions of photography, painting, and
indeed, reality itself.

Marchand’s artistic course, which began in the realm of traditional advertising
photography, has undergone a remarkable evolution. From the tactile intimacy of
35mm film to the grand scale of 20x25-view cameras, and subsequently to the
clinical precision of digital sensors and flatbed scanners, his journey reflects the
tumultuous transformation of the photographic medium over recent decades.

However, it is his current modus operandi that truly stands out. By commandeering
Google Earth’s vast visual database, he has effectively supplanted the physical
camera with an omniscient digital eye, capable of surveying our planet with
unprecedented comprehensiveness.

The fruits of this innovative approach are his Photopaints, a neologism as intriguing
as the works themselves. These pieces occupy a liminal space between photography
and painting, their aesthetic qualities more redolent of the latter despite their
photographic origins. In christening Google Earth’s representation of our planet
MetavEarth, Marchand deftly aligns his work with the zeitgeist of digital immersion
and virtual realities.

Paradoxically, Marchand’s Photopaints, despite their digital genesis, adhere to the
stringent ethos of documentary photography. Like a war correspondent in a virtual
theater, he navigates the simulacrum of Google Earth, his discerning eye seeking out
compelling compositions amidst the digital topography. The resulting images undergo
minimal post-production, limited to color correction and the use of an AI filter
enhancing both depth and details to ensure optimal printing.

The strength of Marchand’s work lies in his exploitation of the glitches and
aberrations inherent in Google Earth’s 3D-rendering algorithms. These digital
artifacts, far from being flaws, become the very essence of his artistic expression.
Through Marchand’s lens, the most scientifically accurate representation of our
planet metamorphoses into a series of naive, almost primitive visual poems.

Perhaps most intriguing is the absence of humanity in these landscapes. Steel
behemoths of industry liquefy under an unseen sun, construction cranes defy gravity
with balloon-like buoyancy, and automobiles appear to be subsumed by the very
roads they traverse. It’s a surreal pageant that simultaneously fascinates and
unsettles.

Initiated in 2016, Marchand’s MetavEarth series spans a diverse range of subjects,
from industrial complexes to urban architecture, from arid deserts to pictorial
homages to architectural luminaries. His virtual peregrinations have led him to the
industrial heartland of Houston, where he unveils an America trapped in a time warp
of industrial decay. In his exploration of Hong Kong, Marchand eschews the
hackneyed skyline shots, instead revealing a city in perpetual flux, teeming with
verdant parks and frenetic construction; while his sharp Singapore outlines the 
ultra-clean environment which made the reputation of the city-state.

In California, Marchand explores the man-mauled deserts and San Francisco bay’s
wetlands, searching for beauty in the scars imposed by society on nature; while at
the same time compiling in another series a comprehensive collection of high-tech
companies’ headquarters from Silicon Valley. By juxtaposing these two facets of
California’s landscape, Marchand presents a nuanced visual dialogue on the duality
of human endeavor – our capacity for both environmental impact and technological
innovation.

In essence, Marchand’s Photopaints represent a paradigm shift in our understanding
of digital art and photography. By repurposing Google Earth’s imagery, he contributes
to a new artistic language that challenges our perception of reality and beauty in the
digital age. His work not only showcases the Earth’s myriad landscapes but also
highlights the unexpected artistry that can emerge from the cold logic of advanced
mapping algorithms.

Marchand stands at the intersection of technology and art, his work a testament to
the transformative power of digital media. In his hands, the photographic record of
our planet becomes a canvas for exploration, interpretation, and ultimately,
transcendence.`,
imageCaption: `Left: self-portrait, 2001, scannogramme
Right: self-portrait, 2017, Remarkable Identities series
Above: 40" x 28" Classic size print in floater frame`,
    }
  };

  const t = translations[currentLanguage];

  return (
    <>
      <div className="w-full pt-16 md:pt-20 px-4 md:pl-[25%] mb-8 md:mb-16">
        <div className="max-w-5xl mx-auto">
          <h2 className="text-2xl md:text-3xl mb-6 md:mb-8">{t.title}</h2>
          
          <div className="flex flex-col lg:flex-row gap-6 lg:gap-12">
            <div className="flex-1 text-lg md:text-xl space-y-4 md:space-y-6">
              <h3 className="text-xl md:text-2xl font-bold">{t.name}</h3>
              <p className="whitespace-pre-line text-justify">{t.bio}</p>
              <p className="whitespace-pre-line text-justify">{t.technique}</p>
              <h4 className="text-xl md:text-2xl font-bold mt-6 md:mt-8">Photopaints on the MetavEarth</h4>
              <p className="whitespace-pre-line text-justify">{t.photopaintsDescription}</p>
              <div className="mt-6 md:mt-8">
                <button 
                  onClick={() => setShowFurtherReading(true)}
                  className="text-brand-blue hover:opacity-70 transition-opacity duration-200 font-bold text-lg md:text-xl"
                >
                  {t.furtherReading} +
                </button>
              </div>
            </div>
   
            <div className="flex-1">
              <div className="flex flex-col gap-4 w-[80%] mx-auto">
                <div className="grid grid-cols-2 gap-4">
                  <div className="w-full">
                    <img 
                      src={profileImage1}
                      alt="Profile 1"
                      className="w-full h-auto object-contain"
                    />
                  </div>
                  <div className="w-full">
                    <img 
                      src={profileImage2}
                      alt="Profile 2"
                      className="w-full h-auto object-contain"
                    />
                  </div>
                </div>
                <div className="w-full">
                  <img 
                    src={profileImage3}
                    alt="Profile 3"
                    className="w-full h-auto object-contain"
                  />
                </div>
                <p className="text-sm text-left mt-2 whitespace-pre-line">{t.imageCaption}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
   
      {showFurtherReading && (
        <div className="fixed inset-0 bg-white z-50 overflow-y-auto">
          <div className="max-w-4xl mx-auto px-4 md:px-8 py-16 md:py-20">
            <button 
              onClick={() => setShowFurtherReading(false)}
              className="fixed top-4 right-4 md:top-8 md:right-8 p-2 hover:opacity-70 transition-opacity duration-200"
            >
              <X className="w-6 h-6 md:w-8 md:h-8" />
            </button>
   
            <div className="space-y-4 md:space-y-6">
              <div className="space-y-4 md:space-y-6 text-lg md:text-xl leading-relaxed">
                {t.furtherReadingText.split('\n\n').map((paragraph, index) => (
                  <p 
                    key={index}
                    dangerouslySetInnerHTML={{ __html: paragraph }}
                    className="text-justify"
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
   );
};

export default AboutPage;