import React, { useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";

const ContactPage = ({ currentLanguage, translations }) => {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [captchaValue, setCaptchaValue] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!captchaValue) {
      alert(translations.captchaError);
      return;
    }

    setIsSubmitting(true);
    try {
      // Replace with your Web3Forms access key
      const accessKey = '769fd754-821a-4f03-8cff-9be43860b6a2';

      const response = await fetch('https://api.web3forms.com/submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          access_key: accessKey,
          ...formData,
          captcha_token: captchaValue,
          from_name: formData.name,
          subject: `New contact form submission from ${formData.name}`,
          language: currentLanguage // Adding language information to the submission
        }),
      });

      const data = await response.json();

      if (response.status === 200) {
        alert(translations.successMessage);
        setFormData({ name: '', email: '', message: '' });
        setCaptchaValue(null);
        if (window.grecaptcha) window.grecaptcha.reset();
      } else {
        alert(translations.errorMessage + (data.message ? `: ${data.message}` : ''));
      }
    } catch (error) {
      console.error('Error:', error);
      alert(translations.errorMessage);
    } finally {
      setIsSubmitting(false);
    }
  };

  const inputClasses = "w-full p-3 bg-gray-100 border-2 border-transparent rounded-md focus:outline-none focus:border-brand-blue transition-colors duration-200";

  return (
    <div className="w-full min-h-screen pt-16 md:pt-20 px-4 md:px-8 lg:pl-[25%]">
      <div className="max-w-5xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-16">
        {/* Form Section */}
        <div>
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <label className="block mb-2 text-brand-blue">{translations.name}</label>
              <input 
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                className={inputClasses}
                required
                disabled={isSubmitting}
              />
            </div>
            <div>
              <label className="block mb-2 text-brand-blue">{translations.email}</label>
              <input 
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                className={inputClasses}
                required
                disabled={isSubmitting}
              />
            </div>
            <div>
              <label className="block mb-2 text-brand-blue">{translations.message}</label>
              <textarea 
                name="message"
                value={formData.message}
                onChange={handleInputChange}
                className={`${inputClasses} h-40 resize-y min-h-[160px]`}
                required
                disabled={isSubmitting}
              />
            </div>
            <div className="my-4">
              <ReCAPTCHA
                sitekey="6Ldh1IgqAAAAADe6fiw0SJWQEcaNaPx6T17H1J2H"
                onChange={(value) => setCaptchaValue(value)}
              />
            </div>
            <button 
              type="submit"
              disabled={isSubmitting}
              className="w-full md:w-auto border-2 border-brand-blue text-brand-blue px-6 py-2.5 rounded-md 
                hover:bg-brand-blue hover:text-white transition-colors duration-200 
                disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {isSubmitting ? translations.sending || 'Sending...' : translations.send}
            </button>
          </form>
        </div>

        {/* Contact Info Section */}
        <div className="space-y-8 mt-8 lg:mt-0">
          {/* Commercial Enquiries - Commented out until available
          <div>
            <h3 className="text-lg font-medium mb-2 text-brand-blue">{translations.commercial}</h3>
            <div className="space-y-1">
              <p>Your Agency Name</p>
              <p>+33 234.567.8900</p>
              <p>email@agency.com</p>
              <p>website.com</p>
            </div>
          </div>
          */}

          {/* Prints & Commissions - Commented out until available
          <div>
            <h3 className="text-lg font-medium mb-2 text-brand-blue">{translations.prints}</h3>
            <p>{translations.contact} studio@yourdomain.com</p>
          </div>
          */}

          <div className="pt-8 text-sm text-gray-600">
            {translations.built} Marchand Dylan Phi
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;