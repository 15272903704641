import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Header from './components/Header/Header';
import HomePage from './components/HomePage/HomePage';
import PhotopaintsPage from './components/PhotopaintsPage/PhotopaintsPage';
import SeriesPage from './components/SeriesPage/SeriesPage';
import AboutPage from './components/AboutPage/AboutPage';
import ContactPage from './components/ContactPage/ContactPage';
import Footer from './components/Footer/Footer';
import NotFound from './components/NotFound/NotFound';
import LoadingSpinner from './components/LoadingSpinner/LoadingSpinner';

const API_ENDPOINT = 'http://localhost:3001/api/contact';

const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => window.scrollTo(0, 0), [pathname]);
  return null;
};

const RouteWrapper = ({ children, title }) => (
  <>
    <Helmet>
      <title>{title}</title>
    </Helmet>
    {children}
  </>
);

function App() {
  const [currentLanguage, setCurrentLanguage] = useState(() => 
    localStorage.getItem('language') || 'fr'
  );
  const [isLoading, setIsLoading] = useState(false);

  const translations = {
    fr: {
      nav: {
        photopaints: 'Photopaints',
        about: 'À propos',
        contact: 'Contact'
      },
      contact: {
        name: 'Nom',
        email: 'Email',
        message: 'Message',
        send: 'Envoyer le message',
        commercial: 'DEMANDES COMMERCIALES:',
        prints: 'IMPRESSIONS ET COMMISSIONS:',
        contact: 'Veuillez contacter',
        built: 'Développé et Conçu par',
        captchaError: 'Veuillez valider le captcha',
        successMessage: 'Message envoyé avec succès!',
        errorMessage: 'Une erreur est survenue. Veuillez réessayer.'
      },
      meta: {
        home: 'Accueil | Renaud Marchand',
        photopaints: 'Photopaints | Renaud Marchand',
        about: 'À propos | Renaud Marchand',
        contact: 'Contact | Renaud Marchand'
      }
    },
    en: {
      nav: {
        photopaints: 'Photopaints', 
        about: 'About',
        contact: 'Contact'
      },
      contact: {
        name: 'Name',
        email: 'Email',
        message: 'Message',
        send: 'Send message',
        commercial: 'COMMERCIAL ENQUIRIES:',
        prints: 'PRINTS & COMMISSIONS:',
        contact: 'Please contact',
        built: 'Designed and Built by',
        captchaError: 'Please verify the captcha',
        successMessage: 'Message sent successfully!',
        errorMessage: 'An error occurred. Please try again.'
      },
      meta: {
        home: 'Home | Renaud Marchand',
        photopaints: 'Photopaints | Renaud Marchand',
        about: 'About | Renaud Marchand',
        contact: 'Contact | Renaud Marchand'
      }
    }
  };

  const handleLanguageChange = (lang) => {
    setIsLoading(true);
    const currentPath = window.location.pathname.split('/').slice(2).join('/');
    setCurrentLanguage(lang);
    localStorage.setItem('language', lang);
    window.location.href = `/${lang}/${currentPath}`;
    setTimeout(() => setIsLoading(false), 300);
  };

  const handleContactSubmit = async (formData, captchaValue) => {
    try {
      const response = await fetch(API_ENDPOINT, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ ...formData, captchaToken: captchaValue })
      });
      return response;
    } catch (error) {
      console.error('Error submitting form:', error);
      throw error;
    }
  };

  return (
    <BrowserRouter>
      <ScrollToTop />
      <div className="min-h-screen bg-white">
        <Header 
          currentLanguage={currentLanguage}
          setCurrentLanguage={handleLanguageChange}
          translations={translations[currentLanguage].nav}
        />
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <main className="w-full">
            <Routes>
              <Route path="/" element={<Navigate to={`/${currentLanguage}`} />} />
              
              <Route path="/:lang">
                <Route index element={
                  <RouteWrapper title={translations[currentLanguage].meta.home}>
                    <HomePage />
                  </RouteWrapper>
                } />
                <Route path="photopaints" element={
                  <RouteWrapper title={translations[currentLanguage].meta.photopaints}>
                    <PhotopaintsPage currentLanguage={currentLanguage} />
                  </RouteWrapper>
                } />
                <Route path="series/:seriesId" element={
                  <SeriesPage currentLanguage={currentLanguage} />
                } />
                <Route path="about" element={
                  <RouteWrapper title={translations[currentLanguage].meta.about}>
                    <AboutPage currentLanguage={currentLanguage} />
                  </RouteWrapper>
                } />
                <Route path="contact" element={
                  <RouteWrapper title={translations[currentLanguage].meta.contact}>
                    <ContactPage 
                      currentLanguage={currentLanguage}
                      translations={translations[currentLanguage].contact}
                      onSubmit={handleContactSubmit}
                    />
                  </RouteWrapper>
                } />
                <Route path="*" element={<NotFound />} />
              </Route>
            </Routes>
          </main>
        )}
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;