import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import SlideShow from '../SlideShow/SlideShow';

      // Mauled Desert imports
      import mauledDesert1 from '../../images/grid/mauled-desert/161113_180716Bvig.jpg';
      import mauledDesert2 from '../../images/grid/mauled-desert/161122_142438vig.jpg';
      import mauledDesert3 from '../../images/grid/mauled-desert/171029_221122Bvig.jpg';
      import mauledDesert4 from '../../images/grid/mauled-desert/190314_1714vig.jpg';
      import mauledDesert5 from '../../images/grid/mauled-desert/190315_1453vig.jpg';
      import mauledDesert6 from '../../images/grid/mauled-desert/220512_1453vig.jpg';
      import mauledDesert7 from '../../images/grid/mauled-desert/220512_1650vig.jpg';
      import mauledDesert8 from '../../images/grid/mauled-desert/240112_1340vig.jpg';
      import mauledDesert9 from '../../images/grid/mauled-desert/240113_1759vig.jpg';
      import mauledDesert10 from '../../images/grid/mauled-desert/240113_1825vig.jpg';
      import mauledDesert11 from '../../images/grid/mauled-desert/240113_1842vig.jpg';
      import mauledDesert12 from '../../images/grid/mauled-desert/240113_1927vig.jpg';

      // Slideshow images imports 
      import slideMauledDesert1 from '../../images/slideshow/mauled-desert/161113_180716Bweb.jpg';
      import slideMauledDesert2 from '../../images/slideshow/mauled-desert/161122_142438web.jpg';
      import slideMauledDesert3 from '../../images/slideshow/mauled-desert/171029_221122Bweb.jpg';
      import slideMauledDesert4 from '../../images/slideshow/mauled-desert/190314_1714web.jpg';
      import slideMauledDesert5 from '../../images/slideshow/mauled-desert/190315_1453web.jpg';
      import slideMauledDesert6 from '../../images/slideshow/mauled-desert/210505_1715web.jpg';
      import slideMauledDesert7 from '../../images/slideshow/mauled-desert/220512_1453web.jpg';
      import slideMauledDesert8 from '../../images/slideshow/mauled-desert/220512_1650web.jpg';
      import slideMauledDesert9 from '../../images/slideshow/mauled-desert/240112_1340web.jpg';
      import slideMauledDesert10 from '../../images/slideshow/mauled-desert/240113_1759web.jpg';
      import slideMauledDesert11 from '../../images/slideshow/mauled-desert/240113_1825web.jpg';
      import slideMauledDesert12 from '../../images/slideshow/mauled-desert/240113_1842web.jpg';
      import slideMauledDesert13 from '../../images/slideshow/mauled-desert/240113_1927web.jpg';
      

      // Oily Grail imports
      import oilyGrail1 from '../../images/grid/oily-grail/160731_182600Qvig.jpg';
      import oilyGrail2 from '../../images/grid/oily-grail/160810_093143vig.jpg';
      import oilyGrail3 from '../../images/grid/oily-grail/160810_100834vig.jpg';
      import oilyGrail4 from '../../images/grid/oily-grail/161010_15.30.21vig.jpg';
      import oilyGrail5 from '../../images/grid/oily-grail/161010_112534vig.jpg';
      import oilyGrail6 from '../../images/grid/oily-grail/161011_094826vig.jpg';
      import oilyGrail7 from '../../images/grid/oily-grail/161011_103227vig.jpg';
      import oilyGrail8 from '../../images/grid/oily-grail/161011_105251Bvig.jpg';
      import oilyGrail9 from '../../images/grid/oily-grail/161011_133410vig.jpg';
      import oilyGrail10 from '../../images/grid/oily-grail/171031_140815Bvig.jpg';
      import oilyGrail11 from '../../images/grid/oily-grail/210421_2221vig.jpg';
      import oilyGrail12 from '../../images/grid/oily-grail/240421_2131vig.jpg';

      // Slideshow images imports 
      import slideOilyGrail1 from '../../images/slideshow/oily-grail/160731_182600Qweb.jpg';
      import slideOilyGrail2 from '../../images/slideshow/oily-grail/160810_093143web.jpg';
      import slideOilyGrail3 from '../../images/slideshow/oily-grail/160810_100834web.jpg';
      import slideOilyGrail4 from '../../images/slideshow/oily-grail/160810_101541web.jpg';
      import slideOilyGrail5 from '../../images/slideshow/oily-grail/161010_15.30.21web.jpg';
      import slideOilyGrail6 from '../../images/slideshow/oily-grail/161010_112534web.jpg';
      import slideOilyGrail7 from '../../images/slideshow/oily-grail/161011_094826web.jpg';
      import slideOilyGrail8 from '../../images/slideshow/oily-grail/161011_103227web.jpg';
      import slideOilyGrail9 from '../../images/slideshow/oily-grail/161011_105251Bweb.jpg';
      import slideOilyGrail10 from '../../images/slideshow/oily-grail/161011_133410web.jpg';
      import slideOilyGrail11 from '../../images/slideshow/oily-grail/171031_140815Bweb.jpg';
      import slideOilyGrail12 from '../../images/slideshow/oily-grail/210421_2221web.jpg';
      import slideoilyGrail13 from '../../images/slideshow/oily-grail/240421_2131web.jpg';

      // Las Vegas Trip imports
      import lasVegas1 from '../../images/grid/las-vegas-trip/200909_1302vig.jpg';
      import lasVegas2 from '../../images/grid/las-vegas-trip/220513_1003vig.jpg';
      import lasVegas3 from '../../images/grid/las-vegas-trip/240511_1546vig.jpg';
      import lasVegas4 from '../../images/grid/las-vegas-trip/240511_1731vig.jpg';
      import lasVegas5 from '../../images/grid/las-vegas-trip/240511_1743vig.jpg';
      import lasVegas6 from '../../images/grid/las-vegas-trip/240514_1455vig.jpg';
      import lasVegas7 from '../../images/grid/las-vegas-trip/240514_1501vig.jpg';
      import lasVegas8 from '../../images/grid/las-vegas-trip/240514_1732vig.jpg';
      import lasVegas9 from '../../images/grid/las-vegas-trip/240514_1839vig.jpg';
      import lasVegas10 from '../../images/grid/las-vegas-trip/240516_1744vig.jpg';
      import lasVegas11 from '../../images/grid/las-vegas-trip/240627_1235vig.jpg';
      import lasVegas12 from '../../images/grid/las-vegas-trip/240627_1323vig.jpg';


      // Slideshow images imports 
      import slideLasVegas1 from '../../images/slideshow/las-vegas/200909_1302web.jpg';
      import slideLasVegas2 from '../../images/slideshow/las-vegas/220513_1003web.jpg';
      import slideLasVegas3 from '../../images/slideshow/las-vegas/240511_1546web.jpg';
      import slideLasVegas4 from '../../images/slideshow/las-vegas/240511_1731web.jpg';
      import slideLasVegas5 from '../../images/slideshow/las-vegas/240511_1743web.jpg';
      import slideLasVegas6 from '../../images/slideshow/las-vegas/240514_1313web.jpg';
      import slideLasVegas7 from '../../images/slideshow/las-vegas/240514_1455web.jpg';
      import slideLasVegas8 from '../../images/slideshow/las-vegas/240514_1501web.jpg';
      import slideLasVegas9 from '../../images/slideshow/las-vegas/240514_1732web.jpg';
      import slideLasVegas10 from '../../images/slideshow/las-vegas/240514_1839web.jpg';
      import slideLasVegas11 from '../../images/slideshow/las-vegas/240516_1744web.jpg';
      import slideLasVegas12 from '../../images/slideshow/las-vegas/240627_1235web.jpg';
      import slideLasVegas13 from '../../images/slideshow/las-vegas/240627_1323web.jpg';

      // Riviera Vanities imports
      import riviera1 from '../../images/grid/riviera-vanities/180103_0945vig.jpg';
      import riviera2 from '../../images/grid/riviera-vanities/180103_1000vig.jpg';
      import riviera3 from '../../images/grid/riviera-vanities/180106_1517vig.jpg';
      import riviera4 from '../../images/grid/riviera-vanities/180106_1737vig.jpg';
      import riviera5 from '../../images/grid/riviera-vanities/180108_0913vig.jpg';
      import riviera6 from '../../images/grid/riviera-vanities/180108_0918vig.jpg';
      import riviera7 from '../../images/grid/riviera-vanities/180322_1309vig.jpg';
      import riviera8 from '../../images/grid/riviera-vanities/180324_1413vig.jpg';
      import riviera9 from '../../images/grid/riviera-vanities/230612_1931vig.jpg';
      import riviera10 from '../../images/grid/riviera-vanities/230612_2017vig.jpg';
      import riviera11 from '../../images/grid/riviera-vanities/230616_1452vig.jpg';
      import riviera12 from '../../images/grid/riviera-vanities/230715_1742vig.jpg';

      // Slideshow images imports 
      import slideRiviera1 from '../../images/slideshow/riviera-vanities/180103_0945web.jpg';
      import slideRiviera2 from '../../images/slideshow/riviera-vanities/180103_1000web.jpg';
      import slideRiviera3 from '../../images/slideshow/riviera-vanities/180106_1517web.jpg';
      import slideRiviera4 from '../../images/slideshow/riviera-vanities/180106_1737web.jpg';
      import slideRiviera5 from '../../images/slideshow/riviera-vanities/180108_0913web.jpg';
      import slideRiviera6 from '../../images/slideshow/riviera-vanities/180108_0918web.jpg';
      import slideRiviera7 from '../../images/slideshow/riviera-vanities/180322_1309web.jpg';
      import slideRiviera8 from '../../images/slideshow/riviera-vanities/180324_1413web.jpg';
      import slideRiviera9 from '../../images/slideshow/riviera-vanities/180324_1417web.jpg';
      import slideRiviera10 from '../../images/slideshow/riviera-vanities/230612_1931web.jpg';
      import slideRiviera11 from '../../images/slideshow/riviera-vanities/230612_2017web.jpg';
      import slideRiviera12 from '../../images/slideshow/riviera-vanities/230616_1452web.jpg';
      import slideRiviera13 from '../../images/slideshow/riviera-vanities/230715_1742web.jpg';

      // Sharp Singapore imports
      import singapore1 from '../../images/grid/sharp-singapore/210813_1657vig.jpg';
      import singapore2 from '../../images/grid/sharp-singapore/210813_1708vig.jpg';
      import singapore3 from '../../images/grid/sharp-singapore/210813_1751vig.jpg';
      import singapore4 from '../../images/grid/sharp-singapore/210813_1755vig.jpg';
      import singapore5 from '../../images/grid/sharp-singapore/210813_1821vig.jpg';
      import singapore6 from '../../images/grid/sharp-singapore/210813_1830vig.jpg';
      import singapore7 from '../../images/grid/sharp-singapore/210813_1843vig.jpg';
      import singapore8 from '../../images/grid/sharp-singapore/210813_1852vig.jpg';
      import singapore9 from '../../images/grid/sharp-singapore/210813_1908vig.jpg';
      import singapore10 from '../../images/grid/sharp-singapore/210813_1911vig.jpg';
      import singapore11 from '../../images/grid/sharp-singapore/220522_1531vig.jpg';
      import singapore12 from '../../images/grid/sharp-singapore/221016_0647vig.jpg';

      // Slideshow images imports 
      import slideSingapore1 from '../../images/slideshow/sharp-singapore/210813_1657web.jpg';
      import slideSingapore2 from '../../images/slideshow/sharp-singapore/210813_1708web.jpg';
      import slideSingapore3 from '../../images/slideshow/sharp-singapore/210813_1751web.jpg';
      import slideSingapore4 from '../../images/slideshow/sharp-singapore/210813_1755web.jpg';
      import slideSingapore5 from '../../images/slideshow/sharp-singapore/210813_1758web.jpg';
      import slideSingapore6 from '../../images/slideshow/sharp-singapore/210813_1821web.jpg';
      import slideSingapore7 from '../../images/slideshow/sharp-singapore/210813_1830web.jpg';
      import slideSingapore8 from '../../images/slideshow/sharp-singapore/210813_1843web.jpg';
      import slideSingapore9 from '../../images/slideshow/sharp-singapore/210813_1852web.jpg';
      import slideSingapore10 from '../../images/slideshow/sharp-singapore/210813_1908web.jpg';
      import slideSingapore11 from '../../images/slideshow/sharp-singapore/210813_1911web.jpg';
      import slideSingapore12 from '../../images/slideshow/sharp-singapore/220522_1531web.jpg';
      import slideSingapore13 from '../../images/slideshow/sharp-singapore/221016_0647web.jpg';

      // From Within imports
      import fromWithin1 from '../../images/grid/from-within/180101_1513vig.jpg';
      import fromWithin2 from '../../images/grid/from-within/180107_1125vig.jpg';
      import fromWithin3 from '../../images/grid/from-within/180206_1039vig.jpg';
      import fromWithin4 from '../../images/grid/from-within/180911_1321vig.jpg';
      import fromWithin5 from '../../images/grid/from-within/181128_1146vig.jpg';
      import fromWithin6 from '../../images/grid/from-within/181128_1203vig.jpg';
      import fromWithin7 from '../../images/grid/from-within/200907_1952vig.jpg';
      import fromWithin8 from '../../images/grid/from-within/200909_0942vig.jpg';
      import fromWithin9 from '../../images/grid/from-within/220108_1707vig.jpg';
      import fromWithin10 from '../../images/grid/from-within/220522_1532vig.jpg';
      import fromWithin11 from '../../images/grid/from-within/240524_1417vig.jpg';
      import fromWithin12 from '../../images/grid/from-within/240529_1739vig.jpg';

      // Slideshow images imports 
      import slideFromWithin1 from '../../images/slideshow/from=within/180101_1513web.jpg';
      import slideFromWithin2 from '../../images/slideshow/from=within/180107_1102web.jpg';
      import slideFromWithin3 from '../../images/slideshow/from=within/180107_1125web.jpg';
      import slideFromWithin4 from '../../images/slideshow/from=within/180206_1039web.jpg';
      import slideFromWithin5 from '../../images/slideshow/from=within/180911_1321web.jpg';
      import slideFromWithin6 from '../../images/slideshow/from=within/181128_1146web.jpg';
      import slideFromWithin7 from '../../images/slideshow/from=within/181128_1203web.jpg';
      import slideFromWithin8 from '../../images/slideshow/from=within/200907_1952web.jpg';
      import slideFromWithin9 from '../../images/slideshow/from=within/200909_0942web.jpg';
      import slideFromWithin10 from '../../images/slideshow/from=within/220108_1707web.jpg';
      import slideFromWithin11 from '../../images/slideshow/from=within/220522_1532web.jpg';
      import slideFromWithin12 from '../../images/slideshow/from=within/240524_1417web.jpg';
      import slideFromWithin13 from '../../images/slideshow/from=within/240529_1739web.jpg';


  const translations = {
    fr: {
      description: {
        'mauled-desert': 'Déserts et zones humides de l’ouest américain mutilés par l’homme',
        'oily-grail': 'Raffineries géantes et sites industriels du Texas',
        'las-vegas-trip': 'Mirages hallucinogènes sur le Strip',
        'riviera-vanities': 'Exclusive villas de la Côte d’Azur',
        'sharp-singapore': 'Survol de l’immaculée ville-état',
        'from-within': 'Bâtiments virtuellement explosés de l’intérieur'
      }
    },
    en: {
      description: {
        'mauled-desert': 'Man-mauled Western United States deserts and wetlands',
        'oily-grail': 'Texas giant oil refineries and chemical plants',
        'las-vegas-trip': 'Hallucinogenic trip on the Strip',
        'riviera-vanities': 'Exclusive plush villas of the French Riviera',
        'sharp-singapore': 'Flying over the ultra-clean City-State',
        'from-within': 'Virtually exploded buildings from the inside out'
      }
    }
  };


  const SeriesPage = ({ currentLanguage }) => {
    const [showSlideshow, setShowSlideshow] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const { seriesId } = useParams();

 const seriesData = {
   'mauled-desert': {
     title: 'Mauled Desert',
     description: translations[currentLanguage].description['mauled-desert'],
     images: [
       mauledDesert1, mauledDesert2, mauledDesert3, mauledDesert4,
       mauledDesert5, mauledDesert6, mauledDesert7, mauledDesert8,
       mauledDesert9, mauledDesert10, mauledDesert11, mauledDesert12
     ],
     slideshowImages: [
       slideMauledDesert1, slideMauledDesert2, slideMauledDesert3, slideMauledDesert4,
       slideMauledDesert5, slideMauledDesert7, slideMauledDesert8, slideMauledDesert9,
      slideMauledDesert10, slideMauledDesert11, slideMauledDesert12, slideMauledDesert13,slideMauledDesert6
     ]
   },
    'oily-grail': {
     title: 'Oily Grail',
     description: translations[currentLanguage].description['oily-grail'],
     images: [
       oilyGrail1, oilyGrail2, oilyGrail3, oilyGrail4,
       oilyGrail5, oilyGrail6, oilyGrail7, oilyGrail8,
       oilyGrail9, oilyGrail10, oilyGrail11, oilyGrail12
     ],
     slideshowImages: [
       slideOilyGrail1, slideOilyGrail2, slideOilyGrail3, slideOilyGrail5,
       slideOilyGrail6, slideOilyGrail7, slideOilyGrail8, slideOilyGrail9,
       slideOilyGrail10, slideOilyGrail11, slideOilyGrail12, slideoilyGrail13, slideOilyGrail4
     ]
   },
   'las-vegas-trip': {
     title: 'Las Vegas Trip',
     description: translations[currentLanguage].description['las-vegas-trip'],
     images: [
       lasVegas12, lasVegas2, lasVegas3, lasVegas4,
       lasVegas5, lasVegas6, lasVegas7, lasVegas8,
       lasVegas9, lasVegas10, lasVegas11, lasVegas1
     ],
     slideshowImages: [
       slideLasVegas13, slideLasVegas2, slideLasVegas3, slideLasVegas4,
       slideLasVegas5, slideLasVegas7, slideLasVegas8, slideLasVegas9,
       slideLasVegas10, slideLasVegas11, slideLasVegas12, slideLasVegas1, slideLasVegas6
     ]
   },
   'riviera-vanities': {
     title: 'Riviera Vanities',
     description: translations[currentLanguage].description['riviera-vanities'],
     images: [
       riviera1, riviera2, riviera3, riviera4,
       riviera5, riviera6, riviera7, riviera8,
       riviera9, riviera10, riviera11, riviera12
     ],
     slideshowImages: [
       slideRiviera1, slideRiviera2, slideRiviera3, slideRiviera4,
       slideRiviera5, slideRiviera6, slideRiviera7, slideRiviera8,
       slideRiviera10, slideRiviera11, slideRiviera12, slideRiviera13, slideRiviera9
     ]
   },
   'sharp-singapore': {
     title: 'Sharp Singapore',
     description: translations[currentLanguage].description['sharp-singapore'],
     images: [
       singapore1, singapore2, singapore3, singapore4,
       singapore5, singapore6, singapore7, singapore8,
       singapore9, singapore10, singapore11, singapore12
     ],
     slideshowImages: [
       slideSingapore1, slideSingapore2, slideSingapore3, slideSingapore4,
       slideSingapore6, slideSingapore7, slideSingapore8, slideSingapore9,
       slideSingapore10, slideSingapore11, slideSingapore12, slideSingapore13, slideSingapore5,
     ]
   },
   'from-within': {
     title: 'From Within',
     description: translations[currentLanguage].description['from-within'],
     images: [
       fromWithin1, fromWithin2, fromWithin3, fromWithin4,
       fromWithin5, fromWithin6, fromWithin7, fromWithin8,
       fromWithin9, fromWithin10, fromWithin11, fromWithin12
     ],
     slideshowImages: [
       slideFromWithin1, slideFromWithin3, slideFromWithin4, slideFromWithin5,
       slideFromWithin6, slideFromWithin7, slideFromWithin8, slideFromWithin9,
       slideFromWithin10, slideFromWithin11, slideFromWithin12, slideFromWithin13, slideFromWithin2
     ]
   }
 };

 if (!seriesData[seriesId]) {
  return <div>Series not found</div>;
}

return (
  <div className="min-h-screen bg-white">
    <div className="w-full px-4 sm:px-6 lg:px-8 pt-16 md:pt-20 lg:pt-24 pb-12 md:pb-16 lg:pb-20 max-w-[1600px] mx-auto">
      <h2 className="text-lg md:text-xl lg:text-2xl xl:text-3xl mb-2 md:mb-3 text-[#4477BB] font-medium tracking-tight">
        {seriesData[seriesId].title}
      </h2>

      <p className="text-base md:text-lg mb-4 md:mb-6 lg:mb-8 max-w-2xl text-gray-700 leading-relaxed">
        {seriesData[seriesId].description}
      </p>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-3 sm:gap-4 md:gap-6 lg:gap-8 mb-12 md:mb-16">
        {seriesData[seriesId].images.slice(0, 12).map((image, index) => (
          <div 
            key={index} 
            className="group cursor-pointer transform transition-all duration-300 hover:scale-[1.02] focus-within:scale-[1.02]"
            onClick={() => {
              setSelectedImageIndex(index);
              setShowSlideshow(true);
            }}
            role="button"
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                setSelectedImageIndex(index);
                setShowSlideshow(true);
              }
            }}
          >
            <div className="relative overflow-hidden rounded-sm">
              <img
                src={image}
                alt={`${seriesData[seriesId].title} - ${index + 1}`}
                className="w-full aspect-[1.62/1] object-cover transition-all duration-300 
                  group-hover:brightness-95 group-focus:brightness-95"
                loading={index > 3 ? "lazy" : "eager"}
                sizes="(max-width: 640px) 100vw, 
                       (max-width: 1024px) 50vw,
                       (max-width: 1536px) 33vw,
                       25vw"
              />
              <div 
                className="absolute inset-0 bg-black/0 group-hover:bg-black/10 
                  group-focus:bg-black/10 transition-colors duration-300" 
                aria-hidden="true"
              />
            </div>
          </div>
        ))}
      </div>
    </div>

    {showSlideshow && (
      <SlideShow
        images={seriesData[seriesId].slideshowImages}
        title={seriesData[seriesId].title}
        initialIndex={selectedImageIndex}
        currentLanguage={currentLanguage}
        onClose={() => {
          setShowSlideshow(false);
          setSelectedImageIndex(0);
        }}
      />
    )}
  </div>
);
};

export default SeriesPage;