import React, { useState, useEffect } from 'react';
import { X, ChevronLeft, ChevronRight } from 'lucide-react';

const SlideShow = ({ images, title, onClose, initialIndex = 0, currentLanguage }) => {
  const [currentIndex, setCurrentIndex] = useState(initialIndex);
  const [isLoading, setIsLoading] = useState(true);

  const translations = {
    fr: {
      close: 'Fermer',
      previous: 'Précédent',
      next: 'Suivant',
      imageOf: 'Image'
    },
    en: {
      close: 'Close',
      previous: 'Previous',
      next: 'Next',
      imageOf: 'Image'
    }
  };

  const t = translations[currentLanguage];

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === 'Escape') handleClose();
      if (e.key === 'ArrowRight') handleNext();
      if (e.key === 'ArrowLeft') handlePrev();
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, []);

  const handleNext = () => {
    setIsLoading(true);
    setCurrentIndex(prev => prev === images.length - 1 ? 0 : prev + 1);
  };

  const handlePrev = () => {
    setIsLoading(true);
    setCurrentIndex(prev => prev === 0 ? images.length - 1 : prev - 1);
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  return (
    <div className="fixed inset-0 bg-white z-50 overflow-hidden">
      <div className="absolute top-0 left-0 right-0 h-16 flex justify-between items-center px-4 sm:px-6 bg-white/90 backdrop-blur-sm z-10">
        <h2 className="text-lg sm:text-xl font-medium text-[#4477BB] truncate">
          {title}
        </h2>
        <button 
          className="p-2 hover:bg-gray-100 rounded-full transition-colors"
          onClick={handleClose}
          aria-label={t.close}
        >
          <X className="w-6 h-6" />
        </button>
      </div>

      <div className="h-full flex items-center justify-center px-4 sm:px-12">
        <button 
          className="absolute left-2 sm:left-4 p-2 hover:bg-gray-100/80 rounded-full transition-colors z-10"
          onClick={handlePrev}
          aria-label={t.previous}
        >
          <ChevronLeft className="w-8 h-8 sm:w-10 sm:h-10" />
        </button>

        <div className="relative w-full h-full flex items-center justify-center pt-16 pb-4">
          {isLoading && (
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="w-8 h-8 border-4 border-gray-200 border-t-[#4477BB] rounded-full animate-spin" />
            </div>
          )}
          <img
            src={images[currentIndex]}
            alt={`${title}`}
            className={`max-h-full max-w-full object-contain transition-opacity duration-300 ${
              isLoading ? 'opacity-0' : 'opacity-100'
            }`}
            onLoad={handleImageLoad}
          />
        </div>

        <button 
          className="absolute right-2 sm:right-4 p-2 hover:bg-gray-100/80 rounded-full transition-colors z-10"
          onClick={handleNext}
          aria-label={t.next}
        >
          <ChevronRight className="w-8 h-8 sm:w-10 sm:h-10" />
        </button>
      </div>

      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="w-8 h-8 border-4 border-gray-200 border-t-[#4477BB] rounded-full animate-spin" />
        </div>
      )}
    </div>
  );
};

export default SlideShow;