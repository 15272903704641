import React from 'react';
import {InstagramIcon, X,} from 'lucide-react';

const Footer = () => {
  return (
    <footer className="fixed bottom-0 left-0 right-0 bg-white/80 backdrop-blur-sm">
      <div className="w-full px-4 sm:px-6 lg:px-[5%] py-3">
        <div className="flex flex-col sm:flex-row justify-between items-center gap-2 sm:gap-0">
          <div className="flex gap-4">
            <a 
              href="https://www.instagram.com/renaud.marchand/" 
              target="_blank" 
              rel="noopener noreferrer"
              className="hover:text-[#4477BB] transition-colors"
              aria-label="Instagram"
            >
              <InstagramIcon className="w-6 h-6 sm:w-8 sm:h-8" />
            </a>
            <a 
              href="https://x.com/OnMetavEarth" 
              target="_blank" 
              rel="noopener noreferrer"
              className="hover:text-[#4477BB] transition-colors"
              aria-label="X (formerly Twitter)"
            >
              <X className="w-6 h-6 sm:w-9 sm:h-9" />
            </a>
          </div>
          <p className="text-xs sm:text-sm">©2024 Renaud Marchand</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;