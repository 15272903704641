import React, { useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Menu, X } from 'lucide-react';

const Header = ({ currentLanguage, setCurrentLanguage, translations }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { lang } = useParams();

  const handleLanguageChange = (newLang) => {
    setCurrentLanguage(newLang);
    const currentPath = location.pathname.split('/').slice(2).join('/');
    navigate(`/${newLang}/${currentPath}`);
  };

  return (
    <header className="w-full px-4 sm:px-6 lg:px-[5%] py-4 bg-white">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-8">
          <Link 
            to={`/${currentLanguage}`}
            className="text-2xl sm:text-3xl lg:text-4xl font-titillium text-[#4477BB] hover:opacity-70 transition-opacity duration-200"
          >
            RENAUD MARCHAND
          </Link>

          <nav className="hidden lg:flex items-center">
            <div className="flex gap-6">
              {Object.entries(translations).map(([key, value]) => (
                <Link
                  key={key}
                  to={`/${currentLanguage}/${key}`}
                  className={`text-base xl:text-lg hover:text-[#4477BB] transition-colors whitespace-nowrap
                    ${location.pathname === `/${currentLanguage}/${key}` ? 'text-[#4477BB]' : ''}`}
                >
                  {value}
                </Link>
              ))}
            </div>
          </nav>
        </div>

        <div className="hidden lg:flex gap-2 text-base xl:text-lg">
          <button 
            onClick={() => handleLanguageChange('fr')}
            className={`px-2 ${currentLanguage === 'fr' ? 'text-[#4477BB] font-bold' : ''}`}
          >
            FR
          </button>
          <span>|</span>
          <button 
            onClick={() => handleLanguageChange('en')}
            className={`px-2 ${currentLanguage === 'en' ? 'text-[#4477BB] font-bold' : ''}`}
          >
            EN
          </button>
        </div>

        <button 
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className="lg:hidden p-2"
        >
          {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
        </button>
      </div>

      {isMenuOpen && (
        <div className="lg:hidden mt-4 pb-4">
          <nav className="flex flex-col gap-4">
            {Object.entries(translations).map(([key, value]) => (
              <Link
                key={key}
                to={`/${currentLanguage}/${key}`}
                onClick={() => setIsMenuOpen(false)}
                className={`text-lg hover:text-[#4477BB] transition-colors
                  ${location.pathname === `/${currentLanguage}/${key}` ? 'text-[#4477BB]' : ''}`}
              >
                {value}
              </Link>
            ))}
            <div className="flex gap-2 text-lg pt-4 border-t">
              <button 
                onClick={() => {
                  handleLanguageChange('fr');
                  setIsMenuOpen(false);
                }}
                className={`px-2 ${currentLanguage === 'fr' ? 'text-[#4477BB] font-bold' : ''}`}
              >
                FR
              </button>
              <span>|</span>
              <button 
                onClick={() => {
                  handleLanguageChange('en');
                  setIsMenuOpen(false);
                }}
                className={`px-2 ${currentLanguage === 'en' ? 'text-[#4477BB] font-bold' : ''}`}
              >
                EN
              </button>
            </div>
          </nav>
        </div>
      )}
    </header>
  );
};

export default Header;