import React, { useState, useEffect } from 'react';
// Your image imports here
import slide1 from '../../images/slide1.jpg';
import slide2 from '../../images/slide2.jpg';
import slide3 from '../../images/slide3.jpg';
import slide4 from '../../images/slide4.jpg';
import slide5 from '../../images/slide5.jpg';
import slide6 from '../../images/slide6.jpg';
import slide7 from '../../images/slide7.jpg';
import slide8 from '../../images/slide8.jpg';
import slide9 from '../../images/slide9.jpg';
import slide10 from '../../images/slide10.jpg';
import slide11 from '../../images/slide11.jpg';
import slide12 from '../../images/slide12.jpg';

const HomePage = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  
  const slides = [
    // Your slides array here
    slide1,
    slide2,
    slide3,
    slide4,
    slide5,
    slide6,
    slide7,
    slide8,
    slide9,
    slide10,
    slide11,
    slide12,
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % slides.length);
    }, 4000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="flex justify-center items-center w-full min-h-screen px-4 sm:px-6 md:px-8">
      <div className="w-full max-w-5xl mx-auto">
        <div className="relative w-full" style={{ aspectRatio: '1.62/1' }}>
          {slides.map((slide, index) => (
            <img
              key={index}
              src={slide}
              alt={`Slide ${index + 1}`}
              className={`absolute top-0 left-0 w-full h-full object-cover transition-opacity duration-1000
                ${index === currentSlide ? 'opacity-100' : 'opacity-0'}`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default HomePage;