import React from 'react';
import { useNavigate } from 'react-router-dom';
import mauledDesert from '../../images/A210505_1715vig.jpg';
import oilyGrail from '../../images/B160810_101541vig.jpg';
import lasVegasTrip from '../../images/C240514_1313vig.jpg';
import rivieraVanities from'../../images/D180324_1417vig.jpg';
import sharpSingapore from '../../images/E210813_1758vig.jpg';
import fromWithin from '../../images/F180107_1102vig.jpg';

const PhotopaintsPage = ({ currentLanguage }) => {
  const navigate = useNavigate();

  const photoSeries = [
    { title: 'Mauled Desert', thumbnail: mauledDesert, id: 'mauled-desert' },
    { title: 'Oily Grail', thumbnail: oilyGrail, id: 'oily-grail' },
    { title: 'Las Vegas Trip', thumbnail: lasVegasTrip, id: 'las-vegas-trip' },
    { title: 'Riviera Vanities', thumbnail: rivieraVanities, id: 'riviera-vanities' },
    { title: 'Sharp Singapore', thumbnail: sharpSingapore, id: 'sharp-singapore' },
    { title: 'From Within', thumbnail: fromWithin, id: 'from-within' }
  ];

  const handleSeriesClick = (seriesId) => {
    navigate(`/${currentLanguage}/series/${seriesId}`);
  };

  return (
    <div className="w-full min-h-screen px-4 pt-16 pb-8 md:pt-20 md:pb-12 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6 xl:gap-8">
          {photoSeries.map((series) => (
            <div 
              key={series.id} 
              className="group cursor-pointer transform transition-all duration-300 hover:scale-[1.02] focus:outline-none"
              onClick={() => handleSeriesClick(series.id)}
              role="button"
              tabIndex={0}
              onKeyDown={(e) => e.key === 'Enter' && handleSeriesClick(series.id)}
            >
              <div className="relative overflow-hidden rounded-sm">
                <img
                  src={series.thumbnail}
                  alt={series.title}
                  className="w-full aspect-[1.62/1] object-cover transition-all duration-300 group-hover:brightness-90"
                  loading="lazy"
                />
                <div 
                  className="absolute inset-0 bg-black/0 group-hover:bg-black/10 transition-colors duration-300" 
                  aria-hidden="true"
                />
              </div>
              <p className="mt-2 text-base md:text-lg font-medium transition-colors duration-300 group-hover:text-[#4477BB]">
                {series.title}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PhotopaintsPage;